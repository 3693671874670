import './styles.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';
import {Link, navigate} from "gatsby";
import {
    welcomeLogo,
    welStarWhite,
    greetingsBack
} from "../../images";

interface MyComponentProps {
    type: string,
    progress: string,
    status: string
}

const WelcomeHeader = ({ type, progress, status } : MyComponentProps) => {

    const handleBackBtn = () => {
        navigate(-1);
    }

    const renderHeader = () => {
        switch(type) {
            case 'type1':
                return <div className="welcomeHeader spec">
                    <div className="welcomeHeaderProgressWrap">
                        <div className={`welcomeHeaderProgress ${status}`}>
                            <div className="welcomeHeaderLine">
                                <span style={{width: `${progress}%`}}></span>
                            </div>
                            <div className="welcomeHeaderNumber welcomeHeaderNumber1">
                                <p>1</p>
                            </div>
                            <div className="welcomeHeaderNumber welcomeHeaderNumber2">
                                <p>2</p>
                            </div>
                            <div className="welcomeHeaderNumber welcomeHeaderNumber3">
                                <p>3</p>
                            </div>
                            <div className="welcomeHeaderNumber welcomeHeaderNumber4">
                                <p>4</p>
                            </div>
                            <div className="welcomeHeaderNumber welcomeHeaderNumber5">
                                <span>
                                    <img src={welStarWhite} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>;
            case 'type2':
                return <div className="welcomeHeader">
                    <div className="welcomeHeaderLogo">
                        <img src={welcomeLogo} alt="" />
                    </div>
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            {renderHeader()}
        </>
    )
}

export default memo(WelcomeHeader);
