import './styles.scss';
import React, {memo, useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Slider from "react-slick";
import {Link, navigate} from "gatsby";
import {toast, ToastContainer} from "react-toastify";
import {
    welGender1,
    welGender2,
    welGender3,
    welAstore,
    welAspiration,
    welEmoji1,
    welEmoji2,
    welEmoji3,
    welEmoji4,
    welEmoji5,
    welEmoji6,
    welEmoji7,
    welEmoji8,
    welEmoji9,
    welEmoji10,
    welEmoji11,
    welEmoji12,
    welEmoji13,
    welcomeBanner1,
    welcomeBanner2,
    welcomeBanner3,
    welcomeBanner4,
    greetingsBook1,
    greetingsBook2,
    greetingsBook3,
    greetingsBook4,
    greetingsNo,
    greetingsYes,
    greetingsStar,
    welDone,
    greetingsSecure,
    greetingsPay4,
    greetingsPay5,
    greetingsPay6,
    greetingsPay8,
    greetingsLock,
    greetingsApay,
    greetingsSafe1,
    welSafe2,
    greetingsSafe3,
    greetingsSafe4,
    welLogin1,
    welLogin2,
    welApple,
    welEmoji14,
    welEmoji15,
    welEmoji16,
    welEmoji17,
    welEmoji18,
    welCourse,
    welAmex,
    welPush
} from "../../images";
import useAnalytics from '../../hooks/useAnalytics';
import WelcomeBanner from '../WelcomeBanner';
import WelcomeHeader from '../WelcomeHeader';

interface MyComponentProps {
    children: {
        id: number,
        type: string,
        page: string,
        next: string
    }
}

const settingsFeed = {
    dots: true,
    arrows: false,
    infinite: true,
    // speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
};

let interval1: NodeJS.Timeout | undefined;
let interval2: NodeJS.Timeout | undefined;
let interval3: NodeJS.Timeout | undefined;
let interval4: NodeJS.Timeout | undefined;
let interval5: NodeJS.Timeout | undefined;

const WelcomePageItem = ({ children } : MyComponentProps) => {

    const bannersList = [
        {
            id: 1,
            icon: welcomeBanner4,
            title: "#1 most downloaded <span>Business-courses</span> app",
            text: "Achieve your goals by listening to and reading the world's best business ideas",
            height: '100',
        },
        {
            id: 2,
            icon: welcomeBanner1,
            title: "Powerful Courses from Top Experts' Books",
            text: "We analyze the wisdom of top books, extract key insights, and build practical courses for you",
            height: '100',
        },
        {
            id: 3,
            icon: welcomeBanner2,
            title: "Listen and read anywhere",
            text: "You can listen and read at the same time without the internet connection",
            height: '100',
        },
        {
            id: 4,
            icon: welcomeBanner3,
            title: "Personal growth challenge",
            text: "Set your learning goals and accept a personalized challenge",
            height: '100',
        },
    ];

    const [running1, setRunning1] = useState(false);
    const [progress1, setProgress1] = useState(0);
    const [modalStatus1, setModalStatus1] = useState(false);

    const [running2, setRunning2] = useState(false);
    const [progress2, setProgress2] = useState(0);
    const [modalStatus2, setModalStatus2] = useState(false);

    const [running3, setRunning3] = useState(false);
    const [progress3, setProgress3] = useState(0);
    const [modalStatus3, setModalStatus3] = useState(false);

    const [running4, setRunning4] = useState(false);
    const [progress4, setProgress4] = useState(0);
    const [modalStatus4, setModalStatus4] = useState(false);

    useEffect(() => {
        if(children.page === 'learning-experience'){
            setRunning1(true);
        }
    }, [children.page]);

    useEffect(() => {
        if (running1) {
            interval1 = setInterval(() => {
                setProgress1((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval1);
        }
    }, [running1]);
    useEffect(() => {
        if (running2) {
            interval2 = setInterval(() => {
                setProgress2((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval2);
        }
    }, [running2]);
    useEffect(() => {
        if (running3) {
            interval3 = setInterval(() => {
                setProgress3((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval3);
        }
    }, [running3]);
    useEffect(() => {
        if (running4) {
            interval4 = setInterval(() => {
                setProgress4((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval4);
        }
    }, [running4]);

    useEffect(() => {
        if (progress1 === 49) {
            clearInterval(interval1);
            setModalStatus1(true);
            setRunning1(false);
        }
        if(progress1 === 100){
            clearInterval(interval1);
            setRunning1(false);
            setRunning2(true);
        }
    }, [progress1]);

    useEffect(() => {
        if (progress2 === 76) {
            clearInterval(interval2);
            setModalStatus2(true);
            setRunning2(false);
        }
        if(progress2 === 100){
            clearInterval(interval2);
            setRunning2(false);
            setRunning3(true);
        }
    }, [progress2]);

    useEffect(() => {
        if (progress3 === 38) {
            clearInterval(interval3);
            setModalStatus3(true);
            setRunning3(false);
        }
        if(progress3 === 100){
            clearInterval(interval3);
            setRunning3(false);
            setRunning4(true);
        }
    }, [progress3]);

    useEffect(() => {
        if (progress4 === 49) {
            clearInterval(interval4);
            setModalStatus4(true);
            setRunning4(false);
        }
        if(progress4 === 100){
            clearInterval(interval4);
            setRunning4(false);
            setNextBtnStatus(false);
        }
    }, [progress4]);
    

    const [nextBtnStatus, setNextBtnStatus] = useState(true);
    const [nextGoalsStatus, setNextGoalsStatus] = useState(true);
    const [checkedAreaCB, setCheckedAreaCB] = useState<string[]>([]);
    const [checkedSeflGrowthCB, setCheckedSeflGrowthCB] = useState<string[]>([]);
    const [checkedNewIdeaCB, setCheckedNewIdeaCB] = useState<string[]>([]);
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();
    // const [email, setEmail] = useState('');
    const [blockHeight, setBlockHeight] = useState<number>(window.innerHeight);
    const [activeTarif, setActiveTarif] = useState('1');
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const renderBanner = (value:number) => {
        return bannersList.map((data) => {
            if(data.id === value){
                return <WelcomeBanner key={data.id} icon={data.icon} title={data.title} text={data.text} height={data.height} checkImageLoad={handleImageLoad} />
            }
        });
    }


    const renderPrice = () => {
        switch(activeTarif) {
            case '1':
                return <div className="welcomePaymentPrice">
                    <div className="welcomePaymentNew">79<span>.98</span> USD</div>
                </div>;
            case '2':
                return <div className="welcomePaymentPrice">
                    <div className="welcomePaymentNew">12<span>.98</span> USD</div>
                </div>;
            case '3':
                return <div className="welcomePaymentPrice">
                    <div className="welcomePaymentNew">4<span>.98</span> USD</div>
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    const handleTarif = (value:string) => {
        localStorage.setItem('greeTarif', value);
        setActiveTarif(value);
    }

    const handleResize = () => {
        setBlockHeight(window.innerHeight);
    };

    useEffect(() => {
        // Date
        // const currentDateObj = new Date();
        // const futureDateObj = new Date();
        // futureDateObj.setDate(currentDateObj.getDate() + 15);

        // const formatOptions = { month: 'long', day: 'numeric' };

        // const formattedCurrentDate = currentDateObj.toLocaleDateString('en-US', formatOptions);
        // const formattedDateIn15Days = futureDateObj.toLocaleDateString('en-US', formatOptions);

        // setCurrentDate(formattedCurrentDate);
        // setDateIn15Days(formattedDateIn15Days);

        const tarif = localStorage.getItem('greeTarif');
        if(tarif && children.page === 'choose-plan'){
            setActiveTarif(tarif);
        } else{
            setActiveTarif('1');
        }
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    const handleModalNext1 = () => {
        setModalStatus1(false);
        setRunning1(true);
    }
    const handleModalNext2 = () => {
        setModalStatus2(false);
        setRunning2(true);
    }
    const handleModalNext3 = () => {
        setModalStatus3(false);
        setRunning3(true);
    }
    const handleModalNext4 = () => {
        setModalStatus4(false);
        setRunning4(true);
    }

    const handleCheckedCB = (array: string[], method:React.Dispatch<React.SetStateAction<string[]>>, value:string) => {
        if(array.includes(value)){
            method(prevArray => prevArray.filter(item => item !== value));
            (array.length === 1) && setNextBtnStatus(true);
        } else if(!array.includes(value)){
            method(prevArray => [...prevArray, value]);
            setNextBtnStatus(false);
        }
    };

    const handleSpecCheckedCB = (array: string[], method:React.Dispatch<React.SetStateAction<string[]>>, value:string) => {
        if(array.includes(value)){
            method(prevArray => prevArray.filter(item => item !== value));
            if(array.length - 1 < 3) {
                // setNextBtnStatus(true);
                setNextGoalsStatus(true);
            }
            if(array.length === 1){
                setNextBtnStatus(true);
            }
        } else if(!array.includes(value)){
            method(prevArray => [...prevArray, value]);
            if(array.length + 1 === 3){
                // setNextBtnStatus(true);
                setNextGoalsStatus(false);
            } else{
                setNextBtnStatus(false);
                setNextGoalsStatus(true);
            }
        }
    };

    const handleRadio = () => {
        setNextBtnStatus(false);
    }

    const handleNextBtn = () => {
        navigate(children.next);
        setNextBtnStatus(true);
        
    }

    const css = `
        body {
            background-color: #473524;
        }
    `;

    const renderPage = () => {
        switch(children.page) {
            case 'start':
                return <div className={`welcomeItem flex ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type2" progress="0" status="false" />

                    <Slider initialSlide={0} {...settingsFeed} className="welcomeSlider">
                        {renderBanner(1)}
                        {renderBanner(2)}
                        {renderBanner(3)}
                        {renderBanner(4)}
                    </Slider>
                    
                    <div className="welcomeBottom">
                        <p className="welcomeBottomText">
                        By continuing, you agree to the <br />
                        <a href="">Privacy Policy</a> and <a href="">Terms & Use</a>
                        </p>
                        <div className="welcomeBottomLinks">
                            <Link to={children.next}>
                                <img src={welLogin1} alt="" />
                            </Link>
                            <Link to={children.next}>
                                <img src={welLogin2} alt="" />
                            </Link>
                        </div>
                        <Link to={children.next} className="welcomeBottomContinue">
                            <img src={welApple} alt="" />
                            <span>Continue with Apple</span>
                        </Link>
                        <p className="welcomeBottomInfo">
                            © AEER PLATFORM INC <br />
                            8 THE GREEN STE A DOVER, DE 19901
                        </p>
                    </div>
                </div>;
            
            case 'lets-customize':
                return <div className={`welcomeItem flex center visible`}>
                    <WelcomeHeader type="type2" progress="0" status="false" />
                    <div className="welcomeContent">
                        <span className="welcomeEmoji">👋</span>
                        <h2 className="welcomeSubtitle small mb8">Glad to have you with us</h2>
                        <p className="welcomeText mb0 tal">Let's customize the platform for you</p>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                        <span>Continue</span>
                    </button>
                </div>;
            case 'gender':
                return <div className={`welcomeItem flex ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="14" status="first" />
                    <p className="welcomeText mb8 fs14">About you</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Select your gender</h2>
                    
                    <div className="welcomeImageAnswers bottom">
                        <div className="welcomeImageAnswer">
                            <input type="radio" name="ageimg" id="ageimg-1" onChange={() => handleRadio()} />
                            <label htmlFor="ageimg-1">
                                <div className="welcomeImageAnswerText">
                                    <span>Male</span>
                                </div>
                                <div className="welcomeImageAnswerIcon">
                                    <img src={welGender1} onLoad={handleImageLoad} alt="" />
                                </div>
                            </label>
                        </div>
                        <div className="welcomeImageAnswer">
                            <input type="radio" name="ageimg" id="ageimg-2" onChange={() => handleRadio()} />
                            <label htmlFor="ageimg-2">
                                <div className="welcomeImageAnswerText">
                                    <span>Female</span>
                                </div>
                                <div className="welcomeImageAnswerIcon">
                                    <img src={welGender2} onLoad={handleImageLoad} alt="" />
                                </div>
                            </label>
                        </div>
                        <div className="welcomeImageAnswer">
                            <input type="radio" name="ageimg" id="ageimg-3" onChange={() => handleRadio()} />
                            <label htmlFor="ageimg-3">
                                <div className="welcomeImageAnswerText">
                                    <span>Other</span>
                                </div>
                                <div className="welcomeImageAnswerIcon">
                                    <img src={welGender3} onLoad={handleImageLoad} alt="" />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed" disabled={nextBtnStatus}>
                        <span>Continue</span>
                    </button>
                    
                </div>;
            case 'your-age':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="25" status="first" />
                    <p className="welcomeText mb8 fs14">About you</p>
                    <h2 className="welcomeTitle small">What is your age?</h2>
                    <div className="welcomeRadios bottom">
                        <div className="welcomeRadio">
                            <input type="radio" name="age" id="age-1"
                                onChange={() => handleRadio()} />
                            <label htmlFor="age-1">
                                {/* <img src={EmojiFace1} alt="" /> */}
                                <span>18-24</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="age" id="age-2"
                                onChange={() => handleRadio()} />
                            <label htmlFor="age-2">
                                {/* <img src={EmojiFace2} alt="" /> */}
                                <span>25-34</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="age" id="age-3"
                                onChange={() => handleRadio()} />
                            <label htmlFor="age-3">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span>35-44</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="age" id="age-4"
                                onChange={() => handleRadio()} />
                            <label htmlFor="age-4">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span>45-54</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="age" id="age-5"
                                onChange={() => handleRadio()} />
                            <label htmlFor="age-5">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span>55+</span>
                            </label>
                        </div>
                    </div>

                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus}>
                            <span>Continue</span>
                        </button>
                </div>;
            case 'your-goals':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="30" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    <h2 className="welcomeTitle mb8 small">What are your goals?</h2>
                    <p className="welcomeText fs14 grey">Choose up to 3 goals for more precise personalization </p>
                    <div className={`welcomeCheckboxes ${(!nextGoalsStatus) ? 'disabled' : ''}`}>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-1"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '1')} />
                            <label htmlFor="self-growth-1">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji1} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👩</span> */}
                                <span className="welcomeCheckboxImageText">Increase productivity </span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-2"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '2')} />
                            <label htmlFor="self-growth-2">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji2} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">📊</span> */}
                                <span className="welcomeCheckboxImageText">Have a successful career</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-3"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '3')} />
                            <label htmlFor="self-growth-3">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji3} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">💏</span> */}
                                <span className="welcomeCheckboxImageText">Start a business</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-4"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '4')} />
                            <label htmlFor="self-growth-4">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji4} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👨‍👩‍👧‍👦</span> */}
                                <span className="welcomeCheckboxImageText">Improve a business</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-5"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '5')} />
                            <label htmlFor="self-growth-5">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji5} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">✈️</span> */}
                                <span className="welcomeCheckboxImageText">Achieve life balance</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-6"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '6')} />
                            <label htmlFor="self-growth-6">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji6} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Boost intelligence</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-7"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '7')} />
                            <label htmlFor="self-growth-7">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji7} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Develop a healthy relationship</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-8"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '8')} />
                            <label htmlFor="self-growth-8">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji8} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Create wealth</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-9"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '9')} />
                            <label htmlFor="self-growth-9">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji9} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Improve sex life</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-10"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '10')} />
                            <label htmlFor="self-growth-10">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji10} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Lead a healthy lifestyle</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-11"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '11')} />
                            <label htmlFor="self-growth-11">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji11} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Reach happiness</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-12"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '12')} />
                            <label htmlFor="self-growth-12">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji12} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Build a close-knit family</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-13"
                                onChange={() => handleSpecCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '13')} />
                            <label htmlFor="self-growth-13">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji13} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span> */}
                                <span className="welcomeCheckboxImageText">Become more confident</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus}>
                            <span>Continue</span>
                        </button>
                </div>;
            case 'choose-area':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="35" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    <h2 className="welcomeTitle small">Choose areas you’d like to elevate</h2>
                    <div className="welcomeCheckboxes tobott gap">
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-12"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '12')} />
                            <label htmlFor="area-12">Motivation</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-13"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '13')} />
                            <label htmlFor="area-13">Leadership</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-14"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '14')} />
                            <label htmlFor="area-14">Management</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-1"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '1')} />
                            <label htmlFor="area-1">Planning</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-2"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '2')} />
                            <label htmlFor="area-2">Time-management</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-3"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '3')} />
                            <label htmlFor="area-3">Parenting</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-4"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '4')} />
                            <label htmlFor="area-4">Self-confidence</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-5"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '5')} />
                            <label htmlFor="area-5">Intimacy</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-6"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '6')} />
                            <label htmlFor="area-6">Mindset</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-7"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '7')} />
                            <label htmlFor="area-7">Emotions</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-8"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '8')} />
                            <label htmlFor="area-8">Nutrition</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-9"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '9')} />
                            <label htmlFor="area-9">Habits</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-10"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '10')} />
                            <label htmlFor="area-10">Self-care</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-11"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '11')} />
                            <label htmlFor="area-11">Exercises</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-15"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '15')} />
                            <label htmlFor="area-15">Empathy</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-16"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '16')} />
                            <label htmlFor="area-16">Self-care</label>
                        </div>
                        <div className="welcomeCheckbox">
                            <input type="checkbox" name="area[]" id="area-17"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '17')} />
                            <label htmlFor="area-17">Communication</label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus}>
                            <span>Continue</span>
                        </button>
                </div>;
            case 'reach-goal':
                return <div className={`welcomeItem flex ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="39" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    <div className="welcomeContent mt48">
                        <div className="welcomeBigimage mb60">
                            <span className="pbi76"></span>
                            <img src={welAspiration} onLoad={handleImageLoad} alt="" />
                        </div>
                        <p className="welcomeSubtitle verysmall fw400">FeelGrow helps you <span>reach your goals efficiently</span> with bite-sized summaries</p>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                        <span>Continue</span>
                    </button>
                </div>;
            case 'how-much-time':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="44" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    <h2 className="welcomeTitle small">How much time would you like to spend on growth daily?</h2>

                    <div className="welcomeRadios bottom">
                        <div className="welcomeRadio">
                            <input type="radio" name="time" id="time-1"
                                onChange={() => handleRadio()} />
                            <label htmlFor="time-1">
                                {/* <img src={EmojiFace1} alt="" /> */}
                                <span>10-20</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="time" id="time-2"
                                onChange={() => handleRadio()} />
                            <label htmlFor="time-2">
                                {/* <img src={EmojiFace2} alt="" /> */}
                                <span>20-30</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="time" id="time-3"
                                onChange={() => handleRadio()} />
                            <label htmlFor="time-3">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span>30-40</span>
                            </label>
                        </div>
                        <div className="welcomeRadio">
                            <input type="radio" name="time" id="time-4"
                                onChange={() => handleRadio()} />
                            <label htmlFor="time-4">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span>40-60</span>
                            </label>
                        </div>
                    </div>
                    
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus} >
                            
                            <span>Continue</span>
                        </button>
                </div>;
            case 'new-ideas':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="47" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    <h2 className="welcomeTitle mb24 small">When do you like to find out new ideas? </h2>
                    <div className="welcomeCheckboxes">
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="new-idea-1"
                                onChange={() => handleCheckedCB(checkedNewIdeaCB, setCheckedNewIdeaCB,  '1')} />
                            <label htmlFor="new-idea-1">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji14} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👩</span> */}
                                <span className="welcomeCheckboxImageText">Increase productivity </span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="new-idea-2"
                                onChange={() => handleCheckedCB(checkedNewIdeaCB, setCheckedNewIdeaCB,  '2')} />
                            <label htmlFor="new-idea-2">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji15} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">📊</span> */}
                                <span className="welcomeCheckboxImageText">Have a successful career</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="new-idea-3"
                                onChange={() => handleCheckedCB(checkedNewIdeaCB, setCheckedNewIdeaCB,  '3')} />
                            <label htmlFor="new-idea-3">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji16} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">💏</span> */}
                                <span className="welcomeCheckboxImageText">Start a business</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="new-idea-4"
                                onChange={() => handleCheckedCB(checkedNewIdeaCB, setCheckedNewIdeaCB,  '4')} />
                            <label htmlFor="new-idea-4">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji17} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👨‍👩‍👧‍👦</span> */}
                                <span className="welcomeCheckboxImageText">Improve a business</span>
                            </label>
                        </div>
                        <div className="welcomeCheckboxImgEmoji">
                            <input type="checkbox" id="new-idea-5"
                                onChange={() => handleCheckedCB(checkedNewIdeaCB, setCheckedNewIdeaCB,  '5')} />
                            <label htmlFor="new-idea-5">
                                <span className="welcomeCheckboxImgEmojiIcon">
                                    <img src={welEmoji18} alt="" />
                                </span>
                                {/* <span className="welcomeCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">✈️</span> */}
                                <span className="welcomeCheckboxImageText">Achieve life balance</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus}>
                            <span>Continue</span>
                        </button>
                </div>;
            case 'push':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="47" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>
                    
                    <div className="welcomeContent">
                        <div className="welcomeBigimage small mb16">
                            <span className="pbi100"></span>
                            <img src={welPush} onLoad={handleImageLoad} alt="" />
                        </div>
                        <p className="welcomeSubtitle verysmall">Push or not to push - you choose!</p>
                        <div className="welcomeToggleChecks">
                            <div className="welcomeToggleCheck">
                                <input type="checkbox" name="push" id="push-1" defaultChecked={true} />
                                <label htmlFor="push-1">
                                    <div className="welcomeToggleCheckInfo">
                                        <span>Morning learning</span>
                                        <p>Get reminders to repeat book ideas and get inspired by daily insights</p>
                                    </div>
                                </label>
                            </div>
                            <div className="welcomeToggleCheck">
                                <input type="checkbox" name="push" id="push-2" defaultChecked={true} />
                                <label htmlFor="push-2">
                                    <div className="welcomeToggleCheckInfo">
                                        <span>Staying on track</span>
                                        <p>Remember to hit your daily reading goal and never lose your streak</p>
                                    </div>
                                </label>
                            </div>
                            <div className="welcomeToggleCheck">
                                <input type="checkbox" name="push" id="push-3" defaultChecked={true} />
                                <label htmlFor="push-3">
                                    <div className="welcomeToggleCheckInfo">
                                        <span>Up to date</span>
                                        <p>We’ll recommend you new gems on your interests</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                            <span>Continue</span>
                        </button>
                </div>;
            case 'comment-1':
                return <div className={`welcomeItem flex visible`}>
                    {/* <style>{css}</style> */}
                    <WelcomeHeader type="type1" progress="49" status="second" />
                    <p className="welcomeText mb8 fs14">Aspiration</p>

                    <div className="welcomeContent mtauto">
                        <div className="welcomeComment">
                            <div className="welcomeReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="welcomeReviewName">by Ben T.</span>
                            <p className="welcomeReviewText">Devoting 15 minutes a day to learning new strategies, trying innovative approaches, and applying them to your business will lead to significant improvements in your entrepreneurial journey."</p>
                        </div>

                        <div className="welcomeStore">
                            <img src={welAstore} alt="" />
                            <span>App Store</span>
                            <p><b>4,8</b> Overall assessment </p>
                        </div>
                    </div>

                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                        <span>Continue</span>
                    </button>
                </div>;
            case 'feed-1':
                return <div className={`welcomeItem flex pb24 visible`}>
                    <WelcomeHeader type="type1" progress="53" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Do you agree with the statement below?</h2>
                    <div className="welcomeContent mtauto">
                        <div className="welcomeFeed">
                            <span>“</span>
                            <p>I enjoy reading, but usually, it’s hard for me to finish whole books.</p>
                        </div>
                    </div>
                    
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'feed-2':
                return <div className={`welcomeItem flex pb24 visible`}>
                    <WelcomeHeader type="type1" progress="55" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Do you agree with the statement below?</h2>
                    <div className="welcomeContent mtauto">
                        <div className="welcomeFeed blue">
                            <span>“</span>
                            <p>I believe that personal growth should bring pleasure, not struggle. </p>
                        </div>
                    </div>
                    
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'book-1':
                return <div className={`welcomeItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="58" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Does this <span>course</span> seem interesting to you?</h2>
                    <div className="welcomeBook">
                        <img src={greetingsBook1} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'book-2':
                return <div className={`welcomeItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="61" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Does this <span>course</span> seem interesting to you?</h2>
                    <div className="welcomeBook">
                        <img src={greetingsBook2} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'book-3':
                return <div className={`welcomeItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="64" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Does this <span>course</span> seem interesting to you?</h2>
                    <div className="welcomeBook">
                        <img src={greetingsBook3} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'book-4':
                return <div className={`welcomeItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="67" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <h2 className="welcomeSubtitle mb24 tac verysmall">Does this <span>course</span> seem interesting to you?</h2>
                    <div className="welcomeBook">
                        <img src={greetingsBook4} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="welcomeBookRadios mtbott">
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="welcomeBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="welcomeBtnImit"></div>
                </div>;
            case 'courses-books':
                return <div className={`welcomeItem flex ${imageLoaded ? 'visible' : ''}`}>
                    <WelcomeHeader type="type1" progress="70" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>
                    <div className="welcomeContent mt48">
                        <div className="welcomeBigimage medium tal mb16">
                            <span className="pbi76"></span>
                            <img src={welCourse} onLoad={handleImageLoad} alt="" />
                        </div>
                        <p className="welcomeSubtitle verysmall fw400"><span>Courses and books you’re interested in</span> were saved to  the Education page!</p>
                    </div>
                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                        <span>Continue</span>
                    </button>
                </div>;
            case 'comment-2':
                return <div className={`welcomeItem flex visible`}>
                    {/* <style>{css}</style> */}
                    <WelcomeHeader type="type1" progress="73" status="third" />
                    <p className="welcomeText mb8 fs14">Like time</p>

                    <div className="welcomeContent mtauto">
                        <div className="welcomeComment">
                            <div className="welcomeReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="welcomeReviewName">by Amy L.</span>
                            <p className="welcomeReviewText">Stop filling up your time with pointless garbage game apps. Download FeelGrow and become the best version of yourself.</p>
                        </div>

                        <div className="welcomeStore">
                            <img src={welAstore} alt="" />
                            <span>App Store</span>
                            <p><b>4,7</b> Overall assessment </p>
                        </div>
                    </div>

                    <div className="welcomeBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">
                        <span>Continue</span>
                    </button>
                </div>;
            case 'learning-experience':
                return <div className="welcomeItem flex visible">
                    <WelcomeHeader type="type1" progress="86" status="fourth" />
                    <h2 className="welcomeSubtitle verysmall mb60">
                        We are crafting your <br />
                        <span>learning experience...</span>
                    </h2>
                    <div className="welcomeProgress">
                        <div className={`welcomeProgressItem ${(progress1 === 100) && 'active'}`}>
                            <span className="welcomeProgressText">
                                {(progress1 === 100) ? 'Goals' : 'Setting goals'}
                            </span>
                            <span className="welcomeProgressNumber">{progress1}%</span>
                            <img src={welDone} alt="" className="welcomeProgressCheck" />
                            <div className="welcomeProgressLine">
                                <span style={{width: `${progress1}%`}}></span>
                            </div>
                        </div>
                        <div className={`welcomeModal ${modalStatus1 && 'active'}`}>
                            {/* <div className="welcomeModalOverlay"></div> */}
                            <div className="welcomeModalInfo">
                                <p>To move forward, specify</p>
                                <h2>Do you self-reflect?</h2>
                                <div className="welcomeModalAnswers">
                                    <button onClick={() => handleModalNext1()}>No</button>
                                    <button onClick={() => handleModalNext1()}>Yes</button>
                                </div>
                            </div>
                        </div>

                        <div className={`welcomeProgressItem ${(progress2 === 100) && 'active'} ${(progress1 === 100) ? '' : 'hidden'}`}>
                            <span className="welcomeProgressText">
                                {(progress1 === 100) ? 'Growth areas' : 'Adapting growth areas'}
                            </span>
                            <span className="welcomeProgressNumber">{progress2}%</span>
                            <img src={welDone} alt="" className="welcomeProgressCheck" />
                            <div className="welcomeProgressLine">
                                <span style={{width: `${progress2}%`}}></span>
                            </div>
                        </div>
                        <div className={`welcomeModal ${modalStatus2 && 'active'}`}>
                            {/* <div className="welcomeModalOverlay"></div> */}
                            <div className="welcomeModalInfo">
                                <p>To move forward, specify</p>
                                <h2>Do you want to give up when things <br /> get hard?</h2>
                                <div className="welcomeModalAnswers">
                                    <button onClick={() => handleModalNext2()}>No</button>
                                    <button onClick={() => handleModalNext2()}>Yes</button>
                                </div>
                            </div>
                        </div>

                        <div className={`welcomeProgressItem ${(progress3 === 100) && 'active'} ${(progress2 === 100) ? '' : 'hidden'}`}>
                            <span className="welcomeProgressText">
                                {(progress1 === 100) ? 'Content' : 'Picking content'}
                            </span>
                            <span className="welcomeProgressNumber">{progress3}%</span>
                            <img src={welDone} alt="" className="welcomeProgressCheck" />
                            <div className="welcomeProgressLine">
                                <span style={{width: `${progress3}%`}}></span>
                            </div>
                        </div>
                        <div className={`welcomeModal ${modalStatus3 && 'active'}`}>
                            {/* <div className="welcomeModalOverlay"></div> */}
                            <div className="welcomeModalInfo">
                                <p>To move forward, specify</p>
                                <h2>Do you like to learn by listening?</h2>
                                <div className="welcomeModalAnswers">
                                    <button onClick={() => handleModalNext3()}>No</button>
                                    <button onClick={() => handleModalNext3()}>Yes</button>
                                </div>
                            </div>
                        </div>

                        <div className={`welcomeProgressItem ${(progress4 === 100) && 'active'} ${(progress3 === 100) ? '' : 'hidden'}`}>
                            <span className="welcomeProgressText">Chellenges</span>
                            <span className="welcomeProgressNumber">{progress4}%</span>
                            <img src={welDone} alt="" className="welcomeProgressCheck" />
                            <div className="welcomeProgressLine">
                                <span style={{width: `${progress4}%`}}></span>
                            </div>
                        </div>
                        <div className={`welcomeModal ${modalStatus4 && 'active'}`}>
                            {/* <div className="welcomeModalOverlay"></div> */}
                            <div className="welcomeModalInfo">
                                <p>To move forward, specify</p>
                                <h2>Are you inclined to finish what you start?</h2>
                                <div className="welcomeModalAnswers">
                                    <button onClick={() => handleModalNext4()}>No</button>
                                    <button onClick={() => handleModalNext4()}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`welcomeEnjoy ${(progress4 > 50) && 'hidden'}`}>
                        <div className="welcomeEnjoyStars">
                            <img src={greetingsStar} alt="" />
                            <img src={greetingsStar} alt="" />
                            <img src={greetingsStar} alt="" />
                            <img src={greetingsStar} alt="" />
                            <img src={greetingsStar} alt="" />
                        </div>
                        <p className="welcomeEnjoyText">Enjoyed by  11 520+ users </p>
                    </div>
                        

                    <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed"
                        disabled={nextBtnStatus}>
                            <span>Continue</span>
                        </button>
                </div>;
            case 'choose-plan':
                return <div className={`welcomeItem pb100 visible`}>
                    <WelcomeHeader type="type1" progress="97" status="fifth" />
                    
                    <div className="welcomeList">
                        <p className="welcomeSubtitle verysmall mb12">Based on your answers <br /> your will begin with:</p>
                        <ul>
                            <li>Personalised growth strategy</li>
                            <li>Personalised courses/books posted daily</li>
                            <li>Personalised Tools (Habits Tracker, Diary)</li>
                            <li>Personalised Al Assistant</li>
                            <li>Guaranteed refund if not found useful</li>
                        </ul>
                    </div>

                    <div className="welcomePlan">
                        <p className="welcomeSubtitle verysmall mb24">Choose your membership </p>
                        <div className="welcomePlanItem">
                            <input type="radio" name="plan" id="plan-1"
                                checked={(activeTarif === '1' ? true : false)}
                                onChange={() => handleTarif('1')} />
                            <label htmlFor="plan-1">
                                <span className="welcomePlanSale">best value · save 64%</span>
                                <div className="welcomePlanName">
                                    <span>Annually</span>
                                    <p>$79,98</p>
                                </div>
                                <div className="welcomePlanPrice">$6,65/month</div>
                            </label>
                        </div>

                        <div className="welcomePlanItem">
                            <input type="radio" name="plan" id="plan-2"
                                checked={(activeTarif === '2' ? true : false)}
                                onChange={() => handleTarif('2')} />
                            <label htmlFor="plan-2">
                                <span className="welcomePlanSale">save 23%</span>
                                <div className="welcomePlanName">
                                    <span>Monthly</span>
                                    <p>$12,99</p>
                                </div>
                                <div className="welcomePlanPrice">$3,24/week</div>
                            </label>
                        </div>

                        <div className="welcomePlanItem">
                            <input type="radio" name="plan" id="plan-3"
                                checked={(activeTarif === '3' ? true : false)}
                                onChange={() => handleTarif('3')} />
                            <label htmlFor="plan-3">
                                <span className="welcomePlanSale">Popular</span>
                                <div className="welcomePlanName">
                                    <span>Introduction</span>
                                    <p><span>Full weekly access just for</span> $4,99</p>
                                </div>
                                <div className="welcomePlanPrice">$0,21/day</div>
                            </label>
                        </div>
                    </div>
                    
                    <div className="welcomeBtnImit"></div>
                    <div className="welcomeFooter">
                        <button onClick={() => handleNextBtn()} className="welcomeNextBtn">
                            <span>Continue</span>
                        </button>
                        <div className="welcomeFooterLinks">
                            <Link to="/terms">Terms & Use</Link>
                            <Link to="/privacy">Privacy Policy</Link>
                        </div>
                    </div>
                    {/* <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">Continue</button> */}
                </div>;

            case 'payment':
                return <div className="welcomeItem visible">
                    <WelcomeHeader type="type1" progress="97" status="fifth" />
                    <div className="welcomePayment">
                        <p className="welcomePaymentTitle">Pay fast & secure with</p>
                        <p className="welcomePaymentText"><span>91% of users</span> stay with us after <br /> the initial subscription</p>
                        <div className="welcomePaymentPrices">
                            <span className="welcomePaymentTotal">Total:</span>
                            {renderPrice()}
                        </div>
                        <div className="welcomePaymentMethods">
                            <img src={greetingsPay4} alt="" />
                            <img src={greetingsPay5} alt="" />
                            <img src={greetingsPay6} alt="" />
                            <img src={greetingsPay8} alt="" />
                            <img src={welAmex} alt="" />
                        </div>
                        <div className="welcomePaymentCard">
                            <span className="welcomePaymentCardName">card</span>
                            <div className="welcomePaymentCardInput">
                                <label htmlFor="card-number">Credit or Debit Card Number</label>
                                <input id="card-number" type="text" placeholder="XXXX XXXX XXXX XXXX" />
                            </div>
                            <div className="welcomePaymentCardInput small">
                                <label htmlFor="card-date">Expiry Date</label>
                                <input id="card-date" type="text" placeholder="MM/YY" />
                            </div>
                            <div className="welcomePaymentCardInput small">
                                <label htmlFor="card-code">CVV/CVC</label>
                                <input id="card-code" type="text" placeholder="CVV" />
                            </div>
                        </div>
                        <button className="welcomePaymentApay">
                            <img src={greetingsApay} alt="" />
                        </button>
                        <button className="welcomePaymentBtn">
                            <img src={greetingsLock} alt="" />
                            <span>Confirm payment</span>
                        </button>
                        
                        <div className="welcomeBuySecure">
                            <span>
                                <img src={greetingsSecure} alt="" />
                                <b>Pay safe & secure</b>
                            </span>
                        </div>
                        <div className="welcomePaymentSafe">
                            <img src={greetingsSafe1} alt="" />
                            <img src={welSafe2} alt="" />
                            <img src={greetingsSafe3} alt="" />
                            <img src={greetingsSafe4} alt="" />
                        </div>
                    </div>
                    
                    {/* <button onClick={() => handleNextBtn()} className="welcomeNextBtn fixed">Continue</button> */}
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            <style>
                {`
                    body{
                        min-height: initial;
                    }
                    .welcomeItem, .welcomeItem.flex{
                        min-height: ${blockHeight}px;
                    }
                `}
            </style>

            {renderPage()}

            <ToastContainer/>
        </>
    )
}

export default memo(WelcomePageItem);
