import '../styles/global.scss';
import '../styles/pages/getstarted.scss';

import {HeadFC} from "gatsby";

// import Slider from "react-slick";
import React, {useEffect, useState, useMemo} from 'react';
// import parse from 'html-react-parser';
// import {Link, navigate} from "gatsby";

import {
    logoOnBoard,
    
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import WelcomePageItem from '../components/WelcomePageItem';


type Props = {
    id: string,
}

const WelcomePage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();
    const pages = [
        {
            id: 1,
            type: '1',
            page: 'start',
            next: '/getstarted/lets-customize',
        },
        {
            id: 2,
            type: '1',
            page: 'lets-customize',
            next: '/getstarted/gender',
        },
        {
            id: 3,
            type: '1',
            page: 'gender',
            next: '/getstarted/your-age',
        },
        {
            id: 4,
            type: '1',
            page: 'your-age',
            next: '/getstarted/your-goals',
        },
        {
            id: 5,
            type: '1',
            page: 'your-goals',
            next: '/getstarted/choose-area',
        },
        {
            id: 6,
            type: '1',
            page: 'choose-area',
            next: '/getstarted/reach-goal',
        },
        {
            id: 7,
            type: '1',
            page: 'reach-goal',
            next: '/getstarted/how-much-time',
        },
        {
            id: 8,
            type: '1',
            page: 'how-much-time',
            next: '/getstarted/new-ideas',
        },
        {
            id: 9,
            type: '1',
            page: 'new-ideas',
            next: '/getstarted/push',
        },
        {
            id: 10,
            type: '1',
            page: 'push',
            next: '/getstarted/comment-1',
        },
        {
            id: 11,
            type: '1',
            page: 'comment-1',
            next: '/getstarted/feed-1',
        },
        {
            id: 12,
            type: '1',
            page: 'feed-1',
            next: '/getstarted/feed-2',
        },
        {
            id: 13,
            type: '1',
            page: 'feed-2',
            next: '/getstarted/book-1',
        },
        {
            id: 14,
            type: '1',
            page: 'book-1',
            next: '/getstarted/book-2',
        },
        {
            id: 15,
            type: '1',
            page: 'book-2',
            next: '/getstarted/book-3',
        },
        {
            id: 16,
            type: '1',
            page: 'book-3',
            next: '/getstarted/book-4',
        },
        {
            id: 17,
            type: '1',
            page: 'book-4',
            next: '/getstarted/courses-books',
        },
        {
            id: 18,
            type: '1',
            page: 'courses-books',
            next: '/getstarted/comment-2',
        },
        {
            id: 19,
            type: '1',
            page: 'comment-2',
            next: '/getstarted/learning-experience',
        },
        {
            id: 20,
            type: '1',
            page: 'learning-experience',
            next: '/getstarted/choose-plan',
        },
        {
            id: 21,
            type: '1',
            page: 'choose-plan',
            next: '/getstarted/payment',
        },
        {
            id: 22,
            type: '1',
            page: 'payment',
            next: '/getstarted/payment',
        },
    ]

    useEffect(() => {
        initAnalytics();
        console.log('Trigger event');
    }, []);

    const renderPage = () => {
        return pages.map((data) => {
            return (id === data.page) && <WelcomePageItem key={data.id} children={data} />
        })
    }

    // const renderPages = () => {
    //     switch(id) {
    //         case 'main':
    //             return <div className="onBoardMain">
                    
    //             </div>;
    //         default:
    //             return <div>This page doesn`t exist</div>;
    //     }
    // }
    const css = `
        body {
            background-color: #101010;
        }
    `;

    return (
        <>
            <style>{css}</style>
            <div className="welcome">
                <div className="welcomeContainer">
                    {renderPage()}
                </div>
            </div>
        </>
    )
}

export default WelcomePage;

export const Head: HeadFC = () => (
    <>
        <title>Welcome - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
